<template>
  <div>
    <b-col
      lg="2"
      cols="6"
    >
      <b-card>
        <WorkersChart
          :orders_in_this_month="orders_in_this_month"
          :orders_month_ago="orders_month_ago"
          :orders_two_months_ago="orders_two_months_ago"
          :orders_three_months_ago="orders_three_months_ago"
        />
      </b-card>
    </b-col>
    <b-col
      lg="2"
      cols="6"
    >
      <h3>
        Przychód
      </h3>
      <b-card>
        <NettoChart
          :income_in_this_month="income_in_this_month"
          :income_month_ago="income_month_ago"
          :income_two_months_ago="income_two_months_ago"
          :income_three_months_ago="income_three_months_ago"
        />
      </b-card>
    </b-col>
    <div class="container rounded border">
      WIZYTY:
      <h2>
        {{ orders_in_this_month.length }}
      </h2>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'
import NettoChart from './NettoChart'
import ClientsChart from './ClientsChart'
import WorkersChart from './WorkersChart.vue'

export default {
  name: 'DailySummary',
  components: {
    BCard,
    BCardText,
    BLink,
    NettoChart,
    ClientsChart,
    WorkersChart,
  },
  props: ['income_in_this_month', 'income_month_ago', 'orders_in_this_month', 'orders_two_months_ago', 'income_two_months_ago', 'income_three_months_ago', 'orders_three_months_ago', 'orders_month_ago'],
  mounted() {
    document.title = 'Podsumowanie miesięczne - Pickmode'
    const ctx = document.getElementById('planet-chart')
    new Chart(ctx, this.planetChartData)
  },
}
</script>

<style>

</style>
